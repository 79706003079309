export const FOREST_GREEN = `#193D12`;
export const GREEN = `#84B23B`;
export const IVORY = `#F8F8F5`;
export const STEEL_BLUE = `#3083BC`;
export const MIDNIGHT_BLUE = `#07004D`;
export const OXFORD_BLUE = `#05052C`;
export const FOCUS_GREEN = `#688a33`;
export const GREY = `#363635`;

export const mediaMinWidth = (width) =>
  `@media screen and (min-width: ${width})`

export const mediaMaxWidth = (width) =>
  `@media screen and (max-width: ${width})`
